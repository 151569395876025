<template>
  <div class="grid grid-cols-2 gap-2">
    <div v-if="details" class="col-span-2">
      {{ details }}
    </div>

    <VLine :label="$t('app.registered_on')">
      <template #value>
        <div>
          <VDate :date="resource?.registered_on || ''" />
        </div>
      </template>
    </VLine>

    <VLine :label="$t('app.completed_on')">
      <template #value>
        <div class="flex items-center">
          <VDate :date="resource?.learner_completed_on || ''" />

          <div v-if="completionTime" class="flex items-center">
            <div class="label space lowercase">
              {{ $t("app.in") }}
            </div>

            {{ completionTime }}
          </div>
        </div>
      </template>
    </VLine>

    <VLine :label="$t('app.completion_deadline')">
      <template #value>
        <div>
          <VDate :date="resource?.completion_deadline || ''" />
        </div>
      </template>
    </VLine>

    <VLine :label="$t('app.completion_approved_on')">
      <template #value>
        <div class="flex items-center">
          <VDate :date="resource?.completion_approved_on || ''" />

          <div v-if="completion_approved_by" class="flex items-center">
            <div class="label space lowercase">
              {{ $t("app.by") }}
            </div>

            {{ completion_approved_by }}
          </div>
        </div>
      </template>
    </VLine>

    <VLine :label="$t('app.certificate_valid_until')">
      <template #value>
        <div>
          <VDate :date="resource?.certificate_valid_until || ''" />

          <VAction
            v-if="download_path"
            icon="download"
            class="ml-1"
            :label="$t('app.download')"
            @click="onClickDownload"
          />
        </div>
      </template>
    </VLine>
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useTexts from "@/composables/useTexts";
import useDisplay from "@/composables/useDisplay";
import useDownload from "@/composables/useDownload";
import useOptions from "@/composables/useOptions";
// Components
import VLine from "@/components/VLine";
import VDate from "@/components/VDate";
import VAction from "@/components/tables/VAction";

export default {
  components: {
    VLine,
    VDate,
    VAction
  },
  props: {
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Composables
    const { downloadFile } = useDownload();
    const { formatSeconds } = useDisplay();
    const { getText } = useTexts();
    const { ALL_OPTIONS } = useOptions();

    // Computed
    const collection = computed(() => props.resource?.collection);
    const details = computed(() => getText(collection.value?.texts, "details"));
    const certificate_file = computed(() => props?.resource?.certificate_file);
    const download_path = computed(() => certificate_file.value?.download_path);
    const result = computed(() => props.resource?.result);
    const hasPassed = computed(() => result.value === ALL_OPTIONS.PASSED.value);
    // eslint-disable-next-line
    const completionTime = computed(() => formatSeconds(props.resource?.completion_time));
    const completion_approved_by = computed(() => {
      // eslint-disable-next-line
      const { firstname, lastname } = props.resource?.completion_approved_by ?? {};

      if (firstname && lastname) {
        return `${firstname} ${lastname}`;
      }

      return "";
    });

    // Methods
    const onClickDownload = () => {
      downloadFile(download_path.value);
    };

    return {
      details,
      completion_approved_by,
      download_path,
      completionTime,
      result,
      hasPassed,
      onClickDownload
    };
  }
};
</script>
