<template>
  <div>
    <ProgressBar :percentage="percentage">
      <template #label>
        <div class="label">
          {{ $t("app.progress") }}
        </div>
      </template>
    </ProgressBar>

    <div class="flex justify-between mt-2">
      <div v-for="(resource, key) in summary" :key="key">
        <VLine :label="$t(`app.${key}`, 2)">
          <template #value>
            <div class="flex items-center">
              <div>
                {{ resource.completed }}
              </div>

              /

              <div class="space">
                {{ resource.required }}
              </div>

              <div class="lowercase">
                {{ $t("app.completed") }}
              </div>
            </div>
          </template>
        </VLine>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
// Components
import ProgressBar from "@/components/ProgressBar";
import VLine from "@/components/VLine";

export default {
  components: {
    ProgressBar,
    VLine
  },
  props: {
    summary: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // Computed
    const summaryKeys = computed(() => Object.keys(props.summary));
    const totalRequired = computed(() => {
      return summaryKeys.value.reduce((acc, key) => {
        const { required } = props.summary[key] ?? {};

        return acc + required;
      }, 0);
    });
    const totalCompleted = computed(() => {
      return summaryKeys.value.reduce((acc, key) => {
        const { completed } = props.summary[key] ?? {};

        return acc + completed;
      }, 0);
    });
    const percentage = computed(() => {
      if (totalRequired.value === 0) {
        return 0;
      }

      const percentage = (100 * totalCompleted.value) / totalRequired.value;

      return percentage.toFixed(0);
    });

    return {
      percentage
    };
  }
};
</script>
