<template>
  <div class="grid grid-cols-1 gap-8">
    <RegistrationCard
      v-for="item in registrations"
      :key="item?.id"
      :resource="item"
    />
  </div>
</template>

<script>
import { computed } from "vue";
// Components
import RegistrationCard from "@/views/learning/registrations/trainings/RegistrationCard";

export default {
  components: {
    RegistrationCard
  },
  props: {
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Computed
    const outline = computed(() => props.resource?.outline ?? []);
    const registrations = computed(() => {
      return outline.value.reduce((acc, training) => {
        const registration = training?.learner_data?.[0] ?? {};
        const value = { ...registration, training };

        return [...acc, value];
      }, []);
    });

    return {
      registrations
    };
  }
};
</script>
